import * as React from 'react';
import { useGetAccount } from '@elrondnetwork/dapp-core/hooks';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import logomag from './../../assets/img/logomag.png';

const Claim = () => {
  const { address } = useGetAccount();

  const [faddress, setAddress] = React.useState(
    address === null ? '' : address
  );
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  React.useEffect(() => {
    setAddress(address === null ? '' : address);
  }, [address]);

  function handleAddressChange(e: React.ChangeEvent<any>) {
    setAddress(e.target.value);
    setError('');
    setSuccess('');
  }

  function handleSubmit() {
    const formdata = {
      address: faddress
    };
    axios.post('https://api.r3d4.fr/xproxy/nft', { formdata }).then((res) => {
      console.log(res);
      console.log(res.data);
      setError(res.data.error);
      setSuccess(res.data.success);
    });
  }

  return (
    <div className='container py-4 greenx'>
      <img className='container' src={logomag} alt='Logo' />
      <p>Claim your NFT</p>
      <Form>
        <Row className='mb-3'>
          <Form.Group
            as={Col}
            md='6'
            controlId='walletAddress'
            onChange={handleAddressChange}
          >
            <Form.Label>Wallet Address :</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='erd1'
              defaultValue={faddress}
            />
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Button variant='primary' onClick={handleSubmit}>
              Claim
            </Button>
          </Form.Group>
        </Row>
      </Form>

      {error && (
        <div className='alert alert-warning' role='alert'>
          {error}
        </div>
      )}
      {success && (
        <div className='alert alert-success' role='alert'>
          {success}
        </div>
      )}
    </div>
  );
};

export default Claim;
